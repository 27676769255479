import { Link as GLink } from 'gatsby'
import React from 'react'
import STYLES from '../../styles/styles'

const Link = ({ to: link, title }: { to: string; title: string }) => {
    return (
        <GLink to={link} className={STYLES.link}>
            {title}
        </GLink>
    )
}

export default Link
