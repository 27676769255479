/* eslint-disable react/no-unescaped-entities */

import React from 'react'
import { Email } from '../../components/shared/email'
import LegalLayout from '../../components/shared/legal-layout'
import Link from '../../components/shared/link'
import {
    DEVELOPER_RU,
    HELP_EMAIL,
    LAST_UPDATED,
    NAME_RU,
} from '../../constants'
import useDomain from '../../hooks/use-domain'

const TermsPage = () => {
    const domain = useDomain()
    return (
        <LegalLayout>
            <article>
                <h1>Пользовательское соглашение</h1>
                <p className="italic text-gray-500 mb-3">
                    Последняя редакция от {LAST_UPDATED}
                </p>
                Перед использованием приложения, пожалуйста, ознакомьтесь с
                условиями нижеследующего Пользовательского соглашения (далее —
                «Соглашение»).
                <br />
                <br />
                <ol className="mt-5">
                    <li>
                        <h2>Общие положения</h2>
                        <ol>
                            <li>
                                Настоящее Соглашение устанавливает условия
                                использования мобильного приложения «{NAME_RU}»
                                (далее - Сервис) заключенное между любым лицом,
                                использующим Сервис (далее - Пользователь, Вы) и
                                {DEVELOPER_RU} являющимся правообладателем
                                исключительного права на Сервис (далее -
                                Правообладатель).
                            </li>
                            <li>
                                Создавая учетную запись в Сервисе, Пользователь
                                тем самым соглашается соблюдать настоящее
                                <Link
                                    to="/ru/legal/terms"
                                    title=" Соглашение"
                                />{' '}
                                и{'  '}
                                <Link
                                    to="/ru/legal/policy"
                                    title=" Политику конфиденциальности"
                                />
                                , которая включена в настоящее Соглашение в виде
                                ссылки.
                            </li>
                            <li>
                                Использование Сервиса разрешается только на
                                условиях настоящего Соглашения. Если
                                Пользователь не принимает условия Соглашения в
                                полном объеме, Пользователь не имеет права
                                использовать Сервис в каких-либо целях.
                                Использование Сервиса с нарушением
                                (невыполнением) какого-либо из условий
                                Соглашения запрещено.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Правомочность</h2>
                        <ol>
                            <li>
                                Вы можете пользоваться Сервисом, только если вам
                                уже исполнилось 18 лет (или более, если в стране
                                вашего проживания установлен более высокий
                                возраст совершеннолетия). Создавая учетную
                                запись и используя Сервис, Вы заявляете и
                                гарантируете, что:
                                <ul>
                                    <li>
                                        Вы подтверждаете, что имеете право и
                                        способны связать себя обязательством
                                        соблюдать Условия и, пользуясь Сервисом,
                                        вы не будете нарушать законов и норм
                                        своей страны. Вы единолично несете
                                        ответственность за соблюдение всех
                                        существующих местных законов и норм.
                                    </li>
                                    <li>
                                        Вы обязуетесь соблюдать настоящее
                                        Соглашение и все действительные местные,
                                        государственные, национальные и
                                        международные законы и нормы;
                                    </li>
                                    <li>
                                        Вы никогда не были признаны судом
                                        виновными в общественно опасном
                                        посягательстве, причинении физического
                                        вреда или сексуальном домогательстве.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Учетная запись</h2>
                        <ol>
                            <li>
                                При общении с нашими представителями по
                                обслуживанию клиентов Вы соглашаетесь вести себя
                                уважительно и добродушно. Если мы считаем, что
                                Ваше поведение по отношению к нашему
                                представителю по обслуживанию клиентов или
                                другим сотрудникам является угрожающим или
                                оскорбительным, мы оставляем за собой право
                                немедленно прекратить действие Вашей учетной
                                записи.
                            </li>
                            <li>
                                В связи с тем, что Правообладатель разрешает вам
                                пользоваться Сервисом, вы соглашаетесь с тем,
                                что Правообладатель, его аффилированные лица и
                                независимые партнеры имеют право размещать
                                рекламу в рамках Сервиса. Отправляя в компанию
                                Правообладатель предложения или отзывы
                                относительно Сервисов, вы соглашаетесь с тем,
                                что Правообладатель имеет право использовать и
                                передавать эти отзывы в любых целях без выплаты
                                вам какой-либо компенсации.
                            </li>
                            <li>
                                Вы соглашаетесь с тем, что Правообладатель имеет
                                право получать, сохранять и раскрывать
                                информацию о Вашей учетной записи и Материалах,
                                если это требуется по закону или если
                                Правообладатель добросовестно считает, что такой
                                доступ, сохранение или разглашение разумно
                                необходимы, например: для выполнения
                                постановления суда; для обеспечения выполнения
                                требований настоящего Соглашения; для ответа на
                                претензии о том, что какие-либо Материалы
                                нарушают права третьих лиц; для ответа на Ваши
                                запросы по обслуживанию клиентов; для защиты
                                права, собственности или обеспечения личной
                                безопасности Правообладателя или любого другого
                                лица.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Правила Сервиса</h2>
                        <ol>
                            <li>
                                Используя Сервис, вы обязуетесь:
                                <ul>
                                    <li>
                                        не использовать Сервис в любых целях,
                                        которые запрещены настоящим Соглашением
                                    </li>
                                    <li>
                                        не использовать Сервис с целью
                                        причинения вреда или со злым умыслом
                                    </li>
                                    <li>
                                        не использовать Сервис с целью
                                        причинения вреда Правообладателю
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Покупки</h2>С помощью Сервиса вы можете совершать
                        покупки через iTunes или Google Play (далее —
                        &quot;Магазин&quot;). Чтобы совершить покупку с помощью
                        Сервиса, необходимо ввести данные вашего аккаунта в
                        Магазине. После этого с вашего аккаунта в Магазине будет
                        взиматься плата за услуги, приобретенные через
                        приложение, в соответствии с условиями, действующими на
                        момент покупки, а также в соответствии с общими
                        условиями покупок, совершаемых через приложения, которые
                        действуют в отношении вашего аккаунта Магазина.
                        Некоторые Магазины могут взимать с вас налог с продаж в
                        зависимости от вашей страны проживания. Если с помощью
                        Сервиса вы покупаете периодическую подписку с
                        автоматическим продлением, плата за подписку будет
                        взиматься с вашего аккаунта Магазина непрерывно до тех
                        пор, пока вы ее не отмените. После истечения срока
                        действия вашей первоначальной подписки и снова после
                        истечения следующего срока действия подписки, ваша
                        подписка будет автоматически продлена на эквивалентный
                        срок по цене, с которой вы согласились при оформлении
                        подписки. Чтобы отменить автоматическое обновление
                        подписки, изменить или прекратить действие подписки,
                        необходимо войти в свой аккаунт Магазина и выполнить
                        указания по отмене, даже если вы удалили свой аккаунт
                        или Сервис с устройства. При удалении аккаунта в Сервисе
                        или Сервиса с устройства подписка не отменяется; Сервис
                        продолжает списывать средства с аккаунта Магазина до
                        того момента, пока не будет отменена подписка через
                        аккаунт Магазина.
                    </li>
                    <li>
                        <h2>Ответственность по Соглашению</h2>
                        <ol>
                            <li>
                                Правообладатель не несет ответственности за
                                какие-либо прямые или косвенные последствия
                                какого-либо использования или невозможности
                                использования Сервиса и/или ущерб, причиненный
                                Пользователю и/или третьим сторонам в результате
                                какого-либо использования, неиспользования или
                                невозможности использования Сервиса, в том числе
                                из-за возможных ошибок или сбоев в работе
                                программно-аппаратных средств, за исключением
                                случаев, прямо предусмотренных
                                законодательством.
                            </li>
                            <li>
                                Все вопросы и претензии, связанные с
                                использованием/невозможностью использования
                                Сервиса, а также возможным нарушением Сервисом
                                законодательства и/или прав третьих лиц, должны
                                направляться через форму обратной связи по
                                адресу электронной почты:{'  '}
                                <Email email={HELP_EMAIL} />.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Заключительные положения</h2>
                        <ol>
                            <li>
                                Соглашение (в том числе любые из его частей)
                                может быть изменена Правообладателем в
                                одностороннем порядке без какого-либо
                                специального уведомления, новая редакция
                                документа вступает в силу с момента размещения в
                                сети Интернет по указанному в настоящем абзаце
                                адресу, если иное не предусмотрено новой
                                редакцией Условий. Действующая редакция Условий
                                всегда находится на странице по адресу:{' '}
                                <Link
                                    to="/ru/legal/terms"
                                    title={`${domain}/ru/legal/terms`}
                                />
                                . В случае, если Правообладателем были внесены
                                какие-либо изменения в настоящее Соглашение, в
                                порядке, предусмотренном данным пунктом, с
                                которыми Пользователь не согласен, он обязан
                                прекратить использование Сервиса.
                            </li>
                            <li>
                                К настоящему Соглашению и всем отношениям,
                                связанным с использованием Сервиса, Программы и
                                Данных, подлежит применению право Республики
                                Беларусь и любые претензии или иски, вытекающие
                                из настоящего Соглашения или использования
                                Сервиса должны быть поданы и рассмотрены в суде
                                по месту нахождения Правообладателя.
                            </li>
                        </ol>
                    </li>
                </ol>
            </article>
        </LegalLayout>
    )
}

export default TermsPage
